(function() {

	function smoothScroll() {
	
		//link to Top
		document.querySelector('.scrollToTop').addEventListener('click', function(e) {
	        e.preventDefault()
	        document.querySelector('.page-wrapper').scrollIntoView({ behavior: 'smooth' })
	    })
	
	}
	
	jQuery(document).ready(smoothScroll)
})()
