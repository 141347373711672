/*(function() {

	function initiateFresco() {

		// set Options for bdzv
		$.extend(Fresco.Skins, {
			'bdzv' : {
				ui: 'outside',
				thumbnails: true,
				spinner: true,
				effects: {
					content: { show: 0, hide: 0 },
					spinner: { show: 150, hide: 150 },
					window: { show: 440, hide: 300 },
					thumbnail: { show: 300, delay: 150 },
					thumbnails: { slide: 0 }
				},
				onShow: function() {
					//alert('Fresco is visible');
				}

			}
		})

		// set skin as default
		Fresco.setDefaultSkin('bdzv')

	}
	jQuery(document).ready(initiateFresco)
})()
*/