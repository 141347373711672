(function() {

	function initialGaleries() {

		$('.galerie-wrapper').each((i, el) => {
			const $el = $(el)
			const $carousel = $el.find('.owl-carousel')

			const $slider = $carousel.owlCarousel({
				items: 1,
				loop:	false,
				margin:	0,
				//center:	false,
				lazyLoad: true,
				dots: true,
				navContainer : $el,
				autoHeight : false,
			})

			// set disabled classes for nav, when needed
			$slider.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
			    if (!event.namespace) return
			    var carousel = event.relatedTarget,
			        element = $el,
			        current = carousel.current()
			    $('.owl-next', element).toggleClass('disabled', current === carousel.maximum())
			    $('.owl-prev', element).toggleClass('disabled', current === carousel.minimum())
			})
		})
	}

	jQuery(document).ready(initialGaleries)
})()