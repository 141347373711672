/*
;addEventListener('load', () => {
	'use strict'

	const charts = document.querySelectorAll('canvas.chart')
	if (!charts.length) return


	/* Chart Colors */
/*
	const _colnames = {}
	let _colErrorMsg = ''
	const getColor = name => {
		const _colors = {
			base: {
				dark: [33, 33, 33],	// #212121
				primary: [3, 50, 76], // #03324C
				secondary: [26, 98, 134],	// #1A6286
				light: [159, 177, 188]	// #9FB1BC
			},
			highlight: {
				red: [164, 36, 59],	// #A4243B
				yellow: [249, 160, 63],	// #F9A03F
				green: [166, 195, 111],	// #A6C36F
				lightblue: [190, 233, 232]	// #BEE9E8
			}
		}

		if (!Object.keys(_colnames).length) {
			for (const colGroup in _colors) {
				for (const col in _colors[colGroup])
					_colnames[colGroup + '_' + col] = _colors[colGroup][col]
			}
			_colErrorMsg = 'Valid color names are: ' + Object.keys(_colnames).join(', ')
		}

		if (!(typeof name === 'string'))
			return _colnames['base_primary'].slice()	// Default color
			//throw 'String argument required instead of type ' + typeof name + '. ' + _colErrorMsg
		if (!_colnames[name])
			throw 'Unknown color "' + name + '". ' + _colErrorMsg
		return _colnames[name].slice()
	}

	const useColor = (name, opacity = null) => {
		if (typeof name === 'undefined')
			return 'transparent'
		const rgb = getColor(name)
		if (opacity !== null) {
			rgb.push(opacity)
			return 'rgba(' + rgb.join(',') + ')'
		} else {
			return rgb.reduce((str, num) => str + num.toString(16), '#')
		}
	}


	/* Data Conversion */
/*
	const convertToPercent = data => {
		data.rows.forEach((el, i) => {
			const sum = el.values.reduce((a, b) => a + b)
			const percentValues = el.values.map(el => el/sum * 100)
			el.valuesOrig = el.values
			el.values = percentValues
		})
		if (data.unit)
			data.unitOrig = data.unit
		if (data.unitShort)
			data.unitShortOrig = data.unitShort
		data.unit = 'Prozent'
		data.unitShort = '%'
		return data
	}

	const chartData = data => {
		if (data.convertToPercent)
			data = convertToPercent(data)
		return data.cols.map((axe, i) => chartDataSingle(data, i))
	}

	const chartDataSingle = (data, index = 0) => ({
		label: data.rows.map(obj => obj.name + (data.unit? ' in ' + data.unit : '')),
		data: data.rows.map(obj => obj.values[index]),
		dataOrig: data.rows[0].valuesOrig?
			data.rows.map(obj => obj.valuesOrig[index]) :
			undefined,
		backgroundColor: data.rows.map(row => useColor(row.color, data.cols[index].opacity || null)),
		borderColor: data.rows.map(row => useColor(row.color)),
		borderWidth: 1
	})

	const tooltipOption = (data, options = {}) => ({
		callbacks: {
			label: (tooltipItem, dataObj) => {
				const label = []
				const datasetIndex = options.datasetIndex || 0
				const afterComma = options.afterComma || 0
				const textLabels = options.textLabels || null
				const datapoints = dataObj.datasets[tooltipItem.datasetIndex].data
				if (!datapoints) return

				if (textLabels === 'col' || textLabels === 'both')
					label.push(data.cols[tooltipItem.datasetIndex].name + ':')
				if (textLabels === 'row' || textLabels === 'both')
					label.push(data.rows[tooltipItem.index].name)

				let output = datapoints[tooltipItem.index].toFixed(afterComma)
				output += data.unitShort? (' ' + data.unitShort) : ''
				label.push(output)
				const datapointsOrig = dataObj.datasets[tooltipItem.datasetIndex].dataOrig
				if (datapointsOrig) {
					let output = '(' + datapointsOrig[tooltipItem.index].toFixed(afterComma)
					output += data.unitShortOrig? (` ${data.unitShortOrig})`) : ')'
					label.push(output)
				}
				return label.join(' ').replace(/\./g, ',')
			}
		}
	})


	/* Chart Types */

// Bar Chart (vertical/horizontal)
/*
	const chartWrapper = {
		bar: data => ({
			data: {
				labels: data.rows.map(obj => obj.name),
				datasets: chartData(data)
			},
			options: {
				scales: {
					xAxes: [{
						stacked: data.stacked === undefined? true : data.stacked
					}],
					yAxes: [{
						stacked: data.stacked === undefined? true : data.stacked
					}]
				},
				tooltips: tooltipOption(data, {
					textLabels: 'col',
					afterComma: data.afterComma === undefined? 1 : data.afterComma
				})
			}
		}),

		horizontalBar: function(data) {
			return this.bar(data)
		},

		// Pie/Doughnut Chart

		pie: data => ({
			data: {
				labels: data.rows.map(obj => obj.name),
				datasets: chartData(data)
			},
			options: {
				tooltips: tooltipOption(data, {
					textLabels: 'both',
					afterComma: data.afterComma === undefined? 1 : data.afterComma
				})
			}
		}),

		doughnut: function(data) {
			return this.pie(data)
		},

		// Line/Area Chart

		line: data => ({
			data: {
				labels: data.rows.map(obj => obj.name),
				datasets: chartData(data).map((dataset, i) =>
					Object.assign(dataset, {
						label: data.cols[i].name,
						backgroundColor: data.isArea? useColor(data.cols[i].color) : 'transparent',
						borderColor: data.isArea? 'transparent' : useColor(data.cols[i].color),
						borderWidth: data.isArea? 0 : 5,
						fill: data.isArea? 'origin' : false,
						pointRadius: data.pointSize || 0,
						pointHoverRadius: 8,
						pointHitRadius: 15,
						pointHoverBackgroundColor: useColor(data.pointColor || 'highlight_red')
					})
				)
			},
			options: {
				scales: {
					yAxes: [{
						stacked: data.stacked === undefined? true : data.stacked
					}]
				},
				tooltips: tooltipOption(data, {
					textLabels: 'col',
					afterComma: data.afterComma === undefined? 1 : data.afterComma
				}),
			}

		}),

		area: function(data) {
			data.type = 'line'
			data.isArea = true
			return this[data.type](data)
		}

	}


	/* Read chart data and pass to chartWrapper function */
/*
	for (let i = 0; i < charts.length; i++) {
		const dataContainer = charts[i].querySelector('script[type="application/json"]')
		if (!dataContainer) {
			console.error('No data for chart', charts[i])
			continue
		}
		const data = JSON.parse(dataContainer.textContent)
		dataContainer.parentNode.removeChild(dataContainer)
		if (!(data.type in chartWrapper)) {
			console.error('No type for chart:', data.type, charts[i])
			continue
		}

		const ctx = charts[i].getContext('2d')
		const settings = chartWrapper[data.type](data)
		settings.type = data.type

		// common settings
		if (settings.options === 'undefined')
			settings.options = {}
		settings.options.responsive = true
		settings.options.legend = {
			display: data.legend === 'none'? false : true,
			position: data.legend? data.legend : 'top'
		}
		settings.options.title = {
			display: data.title === 'none'? false : true,
			text: data.name
		}

		//console.info(settings)
		new Chart(ctx, settings)
	}

})
*/
