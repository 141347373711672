(function() {

	function findBootstrapEnvironment() {

		const envs = ['xs', 'sm', 'md', 'lg', 'xl']

	    const el = document.createElement('div')
	    document.body.appendChild(el)

	    let curEnv = envs.shift()

	    for (const env of envs.reverse()) {
	        el.classList.add(`d-${env}-none`)

	        if (window.getComputedStyle(el).display === 'none') {
	            curEnv = env
	            break
	        }
	    }

	    document.body.removeChild(el)
	    return curEnv

	}
	//jQuery(document).ready(findBootstrapEnvironment)

	const checkToggleButtonType = () => {

		$('.toggle-filter').each((i, el) => {

			const $el = $(el)
			$el.collapse('hide')
			
		})
		
		$('.toggle-content').each((i, el) => {

			const $el = $(el)
			const $checkResolution = findBootstrapEnvironment()

			if (($checkResolution == 'xs') || ($checkResolution == 'sm')) {
				$el.collapse('hide')
			} else {
				$el.collapse('show')
			}

		})
		
		$('.toggle-content-menu').each((i, el) => {

			const $el = $(el)
			const $checkResolution = findBootstrapEnvironment()

			if (($checkResolution == 'xs') || ($checkResolution == 'sm') || ($checkResolution == 'md')) {
				$el.collapse('hide')
			} else {
				$el.collapse('show')
			}

		})
	}
	

	// call on load
	jQuery(document).ready(checkToggleButtonType)

	// call on resize
	window.addEventListener('resize', ev => {
		checkToggleButtonType()
	})

})()