{
	//1er
	const responsiveSettings1 = {
		0:  {items: 1, nav: false, autoWidth: false, autoHeight: false, dots: true},
		575:  {items: 1, nav: false, autoWidth: false, autoHeight: false, dots: true},
		768:  {items: 1, nav: false, autoWidth: false, autoHeight: false, dots: true},
		992:  {items: 1, nav: true, autoWidth: false, autoHeight: false, dots: false},
	}
	
	//2er
	const responsiveSettings2 = {
		0:  {items: 1, nav: true, autoWidth: false, autoHeight: false, dots: true},
		575:  {items: 1, nav: true, autoWidth: false, autoHeight: false, dots: true},
		768:  {items: 2, nav: true, autoWidth: false, autoHeight: false, dots: true},
		992:  {items: 2, nav: true, autoWidth: false, autoHeight: false, dots: false},
	}

	//3er
	const responsiveSettings3 = {
		0:  {items: 1, nav: true, autoWidth: false, autoHeight: false, dots: true},
		575:  {items: 2, nav: true, autoWidth: false, autoHeight: false, dots: true},
		768:  {items: 2, nav: true, autoWidth: false, autoHeight: false, dots: true},
		992:  {items: 3, nav: true, autoWidth: false, autoHeight: false, dots: false},
	}
	
	//4er
	const responsiveSettings4 = {
		0:  {items: 2.5, nav: true, autoWidth: false, autoHeight: false, dots: false, merge: true},
		575:  {items: 2.5, nav: true, autoWidth: false, autoHeight: false, dots: false, merge: true},
		768:  {items: 2.5, nav: true, autoWidth: false, autoHeight: false, dots: false, merge: true},
		992:  {items: 4.2, nav: true, autoWidth: false, autoHeight: false, dots: false, merge: true},
	}
	
	//2er mit unterschiedlichen Breiten
	const responsiveSettings6633 = {
		0:  {items: 1, nav: true, autoWidth: false, autoHeight: false, dots: true},
		575:  {items: 2, nav: true, autoWidth: false, autoHeight: false, dots: true},
		768:  {items: 2, nav: true, autoWidth: false, autoHeight: false, dots: true},
		992:  {items: 2, nav: false, autoWidth: true, autoHeight: false, dots: false},
		1280:  {items: 2, nav: true, autoWidth: true, autoHeight: false, dots: true},
	}
	
	
	
	const setItemWidth = () => {
		$('.article-list--slider-autowidth').each((i, el) => {

			const $el = $(el)

			if(window.innerWidth >= 1280){
				$el.find('.article-list__item--66').css('width',(($el.innerWidth()+10)*0.66)+'px' )
				$el.find('.article-list__item--33').css('width',(($el.innerWidth()+10)*0.33)+'px' )
			}else if(window.innerWidth >= 992){
				$el.find('.article-list__item--66').css('width',(($el.innerWidth()+10)*0.66)+'px' )
				$el.find('.article-list__item--33').css('width',(($el.innerWidth()+10)*0.33)+'px' )
			}else{
				$el.find('.article-list__item--66').removeAttr('style')
				$el.find('.article-list__item--33').removeAttr('style')
			}
		})
	}
	
	
	const slide = () => {
		$('.article-list--slider').each((i, el) => {

			const $el = $(el)

			//initialise Sliders
			const $contentSlider = $el.owlCarousel({
				items: 1,
				responsive: eval('responsiveSettings'+$el.data('slidestoshow')),
				dots: false,
				nav: false,
				autoHeight: false,
				autoWidth: true
			})

			// set disabled classes for nav, when needed
			$contentSlider.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel resized.owl.carousel', function (event) {
			    if (!event.namespace) return
			    var carousel = event.relatedTarget,
			        element = $el,
			        current = carousel.current()
			    $('.owl-next', element).toggleClass('disabled', current === carousel.maximum())
			    $('.owl-prev', element).toggleClass('disabled', current === carousel.minimum())
			})
		})
	}

	jQuery(document).ready(setItemWidth)
	window.addEventListener('resize', ev => {
		setItemWidth()
		slide()
	})
	
	jQuery(document).ready(slide)
	
	
}