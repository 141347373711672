(function() {

	const setArrowsinMainMenu = () => {
		
		$('.main-navi__item--has-dropdown>a span').each((i, el) => {
			const $el = $(el)
			$el.parent().css('width',$el.innerWidth()+'px' )
		})
	
	}
	jQuery(document).ready(setArrowsinMainMenu)
	
	window.addEventListener('resize', ev => {
		setArrowsinMainMenu()
	})

	function hamburger() {
		var $icon = $('.menu-toggle')
		var $menu = $('.offCanvasMainMenu')
		var $header = $('.header')
		var $body = $('body')

		$icon.on('click', function() {
			
			if ($menu.hasClass('is-visible')) {
				$menu.removeClass('is-visible')
				$icon.removeClass('is-active')
				$body.removeClass('overflow-hidden')
			} else {
				$menu.addClass('is-visible')
				$icon.addClass('is-active')
				$header.removeClass('is-visible is-fixed')
				$body.addClass('overflow-hidden')
				setArrowsinMainMenu()

				$('html, body').animate({
					scrollTop: 0
				}, 600)
			}
		})
	}
	jQuery(document).ready(hamburger)

	function cbpAnimatedHeader() {
		var $header = $('.header')
		if ((/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
			$header.addClass('header-shrink')
		} else {

			var docElem = document.documentElement,
				didScroll = false,
				changeHeaderOn = 50

			function init() {
				setTimeout( scrollPage, 20 )
				window.addEventListener( 'scroll', function( event ) {
					if( !didScroll ) {
						didScroll = true
						setTimeout( scrollPage, 20 )
					}
				}, false )
			}

			function scrollPage() {
				var sy = scrollY()
				if ( sy >= changeHeaderOn ) {
					//classie.add( header, '' )
					$header.addClass('header-shrink')
				}
				else {
					$header.removeClass('header-shrink')
				}
				didScroll = false
			}

			function scrollY() {
				return window.pageYOffset || docElem.scrollTop
			}
			init()
		}
	}
	jQuery(document).ready(cbpAnimatedHeader)
})()
