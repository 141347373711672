(function() {
	function easteregg () {
		let $easteregg = ''
		const $logo = $('.page-header__wrapper__inner__logo-wrapper__logo svg.logo')

		/*
			B = 66
			D = 68
			Z = 90
			V = 86
			A = 65
			L = 76
			A = 65
			R = 82
			M = 77
		*/

		$(document).keydown(ev => {
			$easteregg += ev.keyCode
			if ($easteregg.indexOf('666890866576658277') != '-1') {
				$logo.toggleClass('easteregg')
				$easteregg = ''
			}
		})
	}
	jQuery(document).ready(easteregg)
})()
