/* Emulates position: sticky because certain browsers with marginal usage numbers don't support it */
addEventListener('DOMContentLoaded', () => {
	const stAttr = 'data-sticky'
	const stClass = 'visible'

	const $stickyEls = document.querySelectorAll('[' + stAttr + ']')
	if (!$stickyEls.length) return

	const stickies = []
	for (let i = 0; i < $stickyEls.length; i++) {
		const treshold = parseInt($stickyEls[i].getAttribute(stAttr), 10)
		if (treshold && !isNaN(treshold))
			stickies.push({treshold: treshold, $el: $stickyEls[i]})
	}

	const onScroll = function() {
		stickies.forEach(sticky => {
			if (scrollY >= sticky.treshold)
				sticky.$el.classList.add(stClass)
			else
				sticky.$el.classList.remove(stClass)
		})
	}

	onScroll()
	addEventListener('scroll', onScroll)
})
