(function() {
	const isMobile = $(window).width() <= 767? true : false

	function checkOrientation () {
		if (typeof window.orientation == 'undefined') {
			//not a mobile
			return true
		}
		if (Math.abs(window.orientation) != 90) {
			//portrait mode
			$('#landscapeModeHint').fadeOut()
			return true
		} else {
			//landscape mode
			$('#landscapeModeHint').fadeIn().bind('touchstart', function(e) {
				e.preventDefault()
			})
			return false
		}
	}

	if (isMobile){
		$('body').bind('orientationchange', function(e) {
			checkOrientation()
		})
		checkOrientation()
	}
})()
