let owlArrowsPlaced = false

function placeOwlArrows() {
	return
	const $navbars = document.querySelectorAll('.owl-nav')
	if (!$navbars.length) return
	clearInterval(checkOwlArrows)
	const removing = (window.innerWidth > 992)? true : false
	if (removing && !owlArrowsPlaced) return
	const $bodyWidth = document.body.getClientRects()[0].width
	for (let i = 0; i < $navbars.length; i++) {
		$navbars[i].style.transform = ''
		if (removing) {
			$navbars[i].style.width = ''
		} else {
			const $navbarRect = $navbars[i].getClientRects()
			if (!$navbarRect[0]) continue
			const $navbarLeft = $navbarRect[0].left
			$navbars[i].style.width = $bodyWidth + 'px'
			$navbars[i].style.transform = 'translateX(' + $navbarLeft * -1 + 'px)'
			//console.log($navbarLeft * -1, 'translateX(' + $navbarLeft * -1 + 'px)', $navbars[i].style.transform)
			owlArrowsPlaced = true
		}
	}
}

const checkOwlArrows = setInterval(() => {
	if (owlArrowsPlaced) {
		clearInterval(checkOwlArrows)
		return
	}
	placeOwlArrows()
}, 250)

window.addEventListener('resize', ev => {
//	placeOwlArrows()
})
