(function() {
	function checkWebfonts () {
		const font = new FontFaceObserver('sourcesanspro-regular')
		font.load().then(function () {
			console.log('Font loaded.')
		}).catch(function () {
			console.log('Font failed to load.')
		})
	}
	//jQuery(document).ready(checkWebfonts)
})()
