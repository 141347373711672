let hasMouse = true
const touchDevice = 'ontouchstart' in window || navigator.maxTouchPoints
if (touchDevice) {
	hasMouse = false
	const onMouseMove = () => {
	  $(document).off('mousemove', onMouseMove)
	  hasMouse = true
	  $(document).trigger('mouseDetect', hasMouse)
	}
	$(document).on('mousemove', onMouseMove)
}