(function() {
	function checkTabs () {

		// thx to: https://codepen.io/rnr/pen/dMNZmx

		const $hash = window.location.hash
		const $navtab = '.article-filter'

		$hash && $($navtab +' a[href="' + $hash + '"]').tab('show')

		$($navtab + ' a').click(function (e) {
		    $(this).tab('show')
		    var scrollmem = $('body').scrollTop()
		    window.location.hash = this.hash
		    //$('html,body').scrollTop(scrollmem)
		  })


	}
	jQuery(document).ready(checkTabs)
})()
