(function() {
	// see: https://appelsiini.net/projects/lazyload/
	function activateLazyload() {
		$('img.figure__image--lazy').lazyload().removeClass('figure__image--blur')
		picturefill({
			reevaluate: true,
			elements: $('img.figure__image--lazy')
		})
		setTimeout(function(){
			$('img.figure__image--lazy').removeClass('figure__image--lazy')
		}, 2500)
	}

	jQuery(document).ready(activateLazyload)
})()