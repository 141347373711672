(function() {
	function checkAccordeon () {

		const $hash = window.location.hash
		const $navtab = '.accordion'

		//console.log($hash);
		
		//read hash to open active Tab
		if($hash != null && $hash != ''){
			 $(location.hash).collapse('toggle')
		}
		
		//set hash on click
		$($navtab).click(function (e) {		    
		    var hash = $(this).data('target')
			location.hash = hash
		})
	}
	jQuery(document).ready(checkAccordeon)
	
	
	$('.collapse').on('shown.bs.collapse', function(e) {
	  var $card = $(this).closest('.card')
	  $('html,body').animate({
	    scrollTop: ($card.offset().top) - 100
	  }, 500)
	})
	
})()
